<template>
    <div v-if="content!=null">
      <PagesPath :pathTitle1="'تماس با ما'" :pathTo1="'contact'"></PagesPath>
      <section
        class="
          contact
          container
          my-sm-5 my-4
          box-shaddow20
          bg-white

          p-3
        "
      >
        <h5 class="font-weight-bold text-color-444">ارتباط با مهردخت</h5>
        <p class="line-height2 text-color-666 fontsize14 text-justify">
          {{ content.settings.site.about_us_site }}
        </p>

        <form>
          <div class="contact-grid fontsize14">
            <input
              class="bg-color-eai   border p-3"
              ref="name"
              type="text"
              placeholder="نام:"
            />
            <input
              class="bg-color-eai   border p-3"
              ref="phone"
              :type="mediaQueries.mobileSize == true ? 'tel' : 'number'"
              placeholder="شماره تماس:"
            />
            <input
              class="bg-color-eai   border p-3"
              ref="subject"
              type="text"
              placeholder="موضوع:"
            />
          </div>
          <textarea
            class="bg-color-eai   border p-3 w-100 mt-3"
            ref="body"
            id=""
            cols="30"
            rows="10"
            placeholder="پیام:"
          ></textarea>
          <div
            id="contactCheckAndSubmit"
            class="
              d-flex
              justify-content-between
              align-items-baseline
              mt-3
              fontsize14
            "
          >
            <div v-if="question!=null" class="contact-text">
              <svg
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M11.9999 2.75021C17.1089 2.75021 21.2499 6.89221 21.2499 12.0002C21.2499 17.1082 17.1089 21.2502 11.9999 21.2502C6.89188 21.2502 2.74988 17.1082 2.74988 12.0002C2.74988 6.89221 6.89188 2.75021 11.9999 2.75021Z"
                  stroke="#999"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M11.995 8.20432V12.6233"
                  stroke="#999"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M11.995 15.7961H12.005"
                  stroke="#999"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <span class="fontsize13 text-color-999 mr-1">
                {{question}}
              </span>
              <input
                id="answerBox"
                ref="answer"
                class="bg-color-eai   border p-3"
                type="text"
              />
            </div>
            <input
              class="w-25 bg-color-theme   p-3 text-white"
              :class="{ disabled: disabled }"
              @click.prevent="sendForm"
              type="submit"
              value="ارسال پیام"
            />
          </div>
        </form>
        <section
          class="
            contact-info
            container
            badge-dark
            p-md-4 p-3
            mt-3"
        >
          <h5 class="text-white font-weight-bold">اطلاعات تماس</h5>
          <!-- <p class="line-height2 text-white fontsize14 text-justify">
          لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد. کتابهای زیادی در شصت و سه درصد گذشته، حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد. در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها و شرایط سخت تایپ به پایان رسد وزمان مورد نیاز شامل حروفچینی دستاوردهای اصلی و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.
        </p> -->
          <div class="p-3 mb-2 fontsize15   d-flex flex-wrap justify-content-between align-items-baseline">
            <div>
              <span>شماره تماس ما</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="13"
                fill="currentColor"
                class="bi bi-chevron-double-left mt-n1 mr-1"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M8.354 1.646a.5.5 0 0 1 0 .708L2.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                />
                <path
                  fill-rule="evenodd"
                  d="M12.354 1.646a.5.5 0 0 1 0 .708L6.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                />
              </svg>
            </div>
            <div class="">
              <span class="ml-2 fontsize13">{{
                content.settings.site.mobile
              }}</span>
              <!-- <span class="mx-2">/</span> -->
              <!-- <span>021-52036520</span> -->
              <svg
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M2.99033 4.87254C3.30665 4.34878 5.0495 2.44376 6.29322 2.50127C6.665 2.53208 6.99364 2.75699 7.26067 3.01784C7.87379 3.61656 9.62897 5.88101 9.72859 6.35753C9.97096 7.52621 8.57833 8.1999 9.00454 9.37783C10.0911 12.0366 11.9634 13.9088 14.6233 14.9943C15.8003 15.4205 16.474 14.0279 17.6428 14.2713C18.1183 14.3709 20.3839 16.126 20.9826 16.7391C21.2425 17.0051 21.4684 17.3347 21.4992 17.7065C21.5454 19.0159 19.5222 20.7833 19.1278 21.0092C18.1974 21.6747 16.9834 21.6634 15.5035 20.9753C11.3739 19.2572 4.77426 12.7822 3.02422 8.49669C2.35461 7.02505 2.30839 5.80297 2.99033 4.87254Z"
                  stroke="#fff"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
          <div
            class="
              p-3
              mb-2
              fontsize15

              d-flex
              flex-wrap
              justify-content-between
              align-items-baseline
            "
          >
            <div>
              <span>ایمیل ارتباطی</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="13"
                fill="currentColor"
                class="bi bi-chevron-double-left mr-1"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M8.354 1.646a.5.5 0 0 1 0 .708L2.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                />
                <path
                  fill-rule="evenodd"
                  d="M12.354 1.646a.5.5 0 0 1 0 .708L6.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                />
              </svg>
            </div>
            <div class="">
              <span class="ml-2 fontsize13">{{
                content.settings.site.email
              }}</span>
              <svg
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.2677 9.06113L13.0023 12.4954C12.1951 13.1283 11.0635 13.1283 10.2563 12.4954L5.95424 9.06113"
                  stroke="#fff"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6.88787 3.5H16.3158C17.6752 3.51525 18.969 4.08993 19.896 5.0902C20.823 6.09048 21.3022 7.42903 21.222 8.79412V15.322C21.3022 16.6871 20.823 18.0256 19.896 19.0259C18.969 20.0262 17.6752 20.6009 16.3158 20.6161H6.88787C3.96796 20.6161 2 18.2407 2 15.322V8.79412C2 5.87545 3.96796 3.5 6.88787 3.5Z"
                  stroke="#fff"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
          <div
            class="
              p-3
              fontsize15

              d-flex
              flex-wrap
              justify-content-between
              align-items-baseline
            "
          >
            <div>
              <span> آدرس دفترمرکزی </span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="13"
                fill="currentColor"
                class="bi bi-chevron-double-left mr-1"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M8.354 1.646a.5.5 0 0 1 0 .708L2.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                />
                <path
                  fill-rule="evenodd"
                  d="M12.354 1.646a.5.5 0 0 1 0 .708L6.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                />
              </svg>
            </div>
            <div class="">
              <span class="ml-2 fontsize13"
                >{{ content.settings.site.address }}
              </span>
              <svg
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4.23913 10.3913C4.25354 6.15071 7.70289 2.72471 11.9435 2.73912C16.1841 2.75353 19.6101 6.20288 19.5957 10.4435V10.5304C19.5435 13.2869 18.0043 15.8348 16.1174 17.8261C15.0382 18.9467 13.8331 19.9388 12.5261 20.7826C12.1766 21.0849 11.6582 21.0849 11.3087 20.7826C9.3602 19.5143 7.65007 17.9131 6.25652 16.0522C5.01448 14.4294 4.3093 12.4597 4.23913 10.4174L4.23913 10.3913Z"
                  stroke="#fff"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <circle
                  cx="11.9174"
                  cy="10.5391"
                  r="2.46087"
                  stroke="#fff"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
        </section>
      </section>
      <Footer></Footer>
    </div>
</template>

<script>
import PagesPath from "@/parts/Front/components/PagesPath";
export default {
  name: "Contact",
  inject:['mediaQueries'],
  components: {
    PagesPath,
  },
  data() {
    return {
      code: null,question:null,
      disabled: false,
    };
  },
  computed: {
    // دریافت محتوای درخواست هوم
    content() {
      return this.$store.getters["front/getHomeData"];
    },
    loading() {
      return this.$store.getters["front/getHomeDataStatus"];
    },
  },
  mounted() {
    this.$axios.get("all/contacts/create").then((response) => {
      this.code = response.data.data.name;
      this.question=response.data.data.message;

    });
  },
  methods: {
    sendForm() {
      if (this.$refs.name.value.length < 2) {
        this.$refs.name.classList.add("inputError");
      return   window.swal({
          title: "وارد کردن  نام الزامی است",
          text: "نام باید شامل دو کارکتر یا بیشتر باشد",
          icon: "warning",

          showConfirmButton: true,
          dangerMode: true,
          button: "متوجه شدم",
        }).then(()=>{

          this.$refs.name.focus();
        })

      } else {
        this.$refs.name.classList.remove("inputError");
      }
      if (this.$refs.phone.value.length != 11) {
        this.$refs.phone.classList.add("inputError");
       return  window.swal({
          title: "ورود کردن شماره تماس الزامی است",
          text: "شماره تماس شامل 11 رقم است",
          icon: "warning",

          showConfirmButton: true,
          dangerMode: true,
                    button: "متوجه شدم",

        }).then(()=>{

          this.$refs.phone.focus();
        })

      } else {
        this.$refs.phone.classList.remove("inputError");
      }
      if (this.$refs.subject.value.length <3) {
        this.$refs.subject.classList.add("inputError");
       return  window.swal({
          title: "موضوع ارتباط را به درستی وارد کنید",
          icon: "warning",

          showConfirmButton: true,
          dangerMode: true,
                    button: "متوجه شدم",

        }).then(()=>{

          this.$refs.subject.focus();
        })

      } else {
        this.$refs.subject.classList.remove("inputError");
      }
      if (this.$refs.body.value.length <3) {
        this.$refs.body.classList.add("inputError");
        return window.swal({
          title: "متن پیام خود را وارد کنید",
          text: "این متن نباید از 10 کارکتر کمتر باشد",
          icon: "warning",

          showConfirmButton: true,
          dangerMode: true,
                    button: "متوجه شدم",

        }).then(()=>{

          this.$refs.body.focus();
        })
      } else {
        this.$refs.body.classList.remove("inputError");
      }
      if (this.$refs.answer.value.length <1) {
        this.$refs.answer.classList.add("inputError");
        return window.swal({
          title: "پاسخ سوال امنیتی را وارد کنید",
          icon: "warning",

          showConfirmButton: true,
          dangerMode: true,
                    button: "متوجه شدم",

        }).then(()=>{

          this.$refs.answer.focus();
        })

      } else {
        this.$refs.answer.classList.remove("inputError");
      }
      const formData = new FormData();
      formData.append("name", this.$refs.name.value);
      formData.append("phone_number", this.$refs.phone.value);
      formData.append("subject", this.$refs.subject.value);
      formData.append("body", this.$refs.body.value);
      formData.append("" + this.code, this.$refs.answer.value);

        this.disabled = true;
        this.$axios.post("/all/contacts", formData).then((res) => {
          this.disabled = false;
          this.$refs.name.value = null;
          this.$refs.phone.value = null;
          this.$refs.subject.value = null;
          this.$refs.body.value = null;
          this.$refs.answer.value = null;
          window.swal({
            title: "ارتباط با مهردخت برقرار شد",
            text: "پیام شما در سیستم ما درج و به زودی پاسخ آنرا دریافت خواهید کرد باتشکر!",
            icon: "success",

            showConfirmButton: true,
                    button: "متوجه شدم",
          });
        }).catch(err=>{
          this.disabled=false;
              window.swal({
          title: "پاسخ سوال امنیتی را  به درستی وارد نکرده اید",
          icon: "warning",

          showConfirmButton: true,
          dangerMode: true,
                    button: "متوجه شدم",

        }).then(()=>{

          this.$refs.answer.focus();
        })
        });

    },
  },
};
</script>

<style scoped>
/* برداشتن دکمه های بالا پایین برای ورود عدد */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
#answerBox {
  padding: 5px !important;
  width: 70px;
  border-radius: 4px;
}
#contactCheckAndSubmit {
  display: flex;
  flex-wrap: wrap;
}
.contact-text {
  margin-bottom: 10px;
}
.disabled {
  pointer-events: none;
  opacity: 0.5;
}
.inputError {
  border: 1px solid red !important;
  background: #efe1e4;
}
</style>
